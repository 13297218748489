/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeDto,
    EmployeeDtoFromJSON,
    EmployeeDtoFromJSONTyped,
    EmployeeDtoToJSON,
    LeaveRecordStatus,
    LeaveRecordStatusFromJSON,
    LeaveRecordStatusFromJSONTyped,
    LeaveRecordStatusToJSON,
    LeaveType,
    LeaveTypeFromJSON,
    LeaveTypeFromJSONTyped,
    LeaveTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaveRecordDto
 */
export interface LeaveRecordDto {
    /**
     * 
     * @type {number}
     * @memberof LeaveRecordDto
     */
    id: number;
    /**
     * 
     * @type {LeaveType}
     * @memberof LeaveRecordDto
     */
    type: LeaveType;
    /**
     * 
     * @type {EmployeeDto}
     * @memberof LeaveRecordDto
     */
    employee: EmployeeDto;
    /**
     * 
     * @type {Date}
     * @memberof LeaveRecordDto
     */
    fromDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof LeaveRecordDto
     */
    toDate: Date;
    /**
     * 
     * @type {LeaveRecordStatus}
     * @memberof LeaveRecordDto
     */
    status: LeaveRecordStatus;
    /**
     * 
     * @type {string}
     * @memberof LeaveRecordDto
     */
    note?: string;
}

export function LeaveRecordDtoFromJSON(json: any): LeaveRecordDto {
    return LeaveRecordDtoFromJSONTyped(json, false);
}

export function LeaveRecordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveRecordDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': LeaveTypeFromJSON(json['type']),
        'employee': EmployeeDtoFromJSON(json['employee']),
        'fromDate': (new Date(json['fromDate'])),
        'toDate': (new Date(json['toDate'])),
        'status': LeaveRecordStatusFromJSON(json['status']),
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function LeaveRecordDtoToJSON(value?: LeaveRecordDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': LeaveTypeToJSON(value.type),
        'employee': EmployeeDtoToJSON(value.employee),
        'fromDate': (value.fromDate.toISOString().substr(0,10)),
        'toDate': (value.toDate.toISOString().substr(0,10)),
        'status': LeaveRecordStatusToJSON(value.status),
        'note': value.note,
    };
}


