import { useQuery, UseQueryResult } from 'react-query';
import { Configuration, EmployeeControllerApi, EmployeeDto } from 'generated';
import useAccessToken from 'common/liff/useAccessToken';

const EMPLOYEE_SELF_KEY = 'EMPLOYEE_SELF';

const useEmployeeSelf = (): UseQueryResult<EmployeeDto | undefined, Response> => {
  const accessToken = useAccessToken();
  const api = new EmployeeControllerApi(
    new Configuration({
      basePath: '',
      accessToken,
    })
  );
  return useQuery(
    `${EMPLOYEE_SELF_KEY}_${accessToken}`,
    async () => {
      if (!accessToken) {
        return undefined;
      }
      return await api.getSelf();
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1, // one time is enough
    }
  );
};

export default useEmployeeSelf;
