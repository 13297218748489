import { ValidationResultObject, ValidatorName } from 'generated';

export default function createGenericValidationResult(): ValidationResultObject {
  return {
    success: false,
    validatorName: ValidatorName.None,
    message: 'เกิดข้อผิดพลาด',
    additionalData: [],
  };
}
