import React, { useEffect, useState } from 'react';
import CssTransition from 'common/components/css-transition/CssTransition';
import { useLocation } from 'react-router-dom';

interface Props {
  name?: string;
  paths: { path: string; title: string }[];
}

const CardHeader: React.FC<Props> = (props) => {
  const [shouldShow, setShouldShow] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setShouldShow(true);
  }, []);
  const renderTitles = () => {
    return props.paths.map((pathTitle) => (
      <CssTransition
        key={pathTitle.path}
        in={shouldShow && location.pathname === pathTitle.path}
        timeout={400}
        classNames="Anime-Slide"
      >
        {(ref) => (
          <span className="absolute left-0 top-1" ref={ref}>
            {pathTitle.title}
          </span>
        )}
      </CssTransition>
    ));
  };

  const renderName = () => {
    return (
      props.name && (
        <span className="absolute text-xl bg-cyan-500 text-white px-3 py-1 rounded-xl right-0 top-0">{props.name}</span>
      )
    );
  };

  return (
    <div className="text-2xl font-semibold border-b border-blue-200 pb-1 mb-2 relative h-10">
      {renderTitles()}
      {renderName()}
    </div>
  );
};

export default CardHeader;
