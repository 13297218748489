import React from 'react';
import LeaveStartForm from 'pages/leave/pages/leave-start/LeaveStartForm';
import { useDispatch, useSelector } from 'react-redux';
import { RootLeaveState } from 'pages/leave/store/LeaveStore';
import { actions as LeaveActions, LeaveSlice } from 'pages/leave/store/LeaveSlice';
import { useHistory } from 'react-router-dom';
import tomorrow from 'common/utility/tomorrow';
import formatLocalDate from 'common/utility/formatLocalDate';
import { LeaveValues } from 'pages/leave/pages/leave-start/LeaveValues';

const LeaveStart: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const leaveSlice = useSelector<RootLeaveState, LeaveSlice>((state) => state.leave);
  const handleSubmitForm = (values: LeaveValues) => {
    dispatch(
      LeaveActions.setLeave({
        type: values.leaveType,
        fromDate: new Date(values.fromDate).getTime(),
        toDate: new Date(values.toDate).getTime()
      })
    );
    history.push('/confirm');
  };
  return (
    <LeaveStartForm
      onSubmit={handleSubmitForm}
      type={leaveSlice.type}
      fromDate={formatLocalDate(leaveSlice.fromDate || tomorrow())}
      toDate={formatLocalDate(leaveSlice.toDate || tomorrow())}
    />
  );
};

export default LeaveStart;
