import { useEffect } from 'react';
import { addMinutes, isAfter } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { CredentialState } from 'common/redux/credential/CredentialState';
import { actions as CredentialActions, CredentialSlice } from 'common/redux/credential/CredentialSlice';
import { useLiffContext } from 'common/liff/Liff';
import { lineAccessTokenKey } from 'common/Constant';

const useAccessToken = (): string | undefined => {
  const dispatch = useDispatch();
  const accessToken = useSelector<CredentialState, string | undefined>((state) => state.credential.accessToken);
  const expiredDate = useSelector<CredentialState, number | undefined>((state) => state.credential.expiredDate);

  const liffContext = useLiffContext();

  useEffect(() => {
    if (accessToken && expiredDate && isAfter(expiredDate, new Date())) {
      return;
    }

    const tokenJson = sessionStorage.getItem(lineAccessTokenKey);
    if (tokenJson) {
      const token = JSON.parse(tokenJson) as Required<CredentialSlice>;
      if (isAfter(token.expiredDate, new Date())) {
        dispatch(CredentialActions.setCredential(token));
      } else {
        sessionStorage.removeItem(lineAccessTokenKey);
        dispatch(CredentialActions.removeCredential());
      }
    } else {
      const newAccessToken = liffContext.liff?.getAccessToken?.();
      if (newAccessToken) {
        const tokenStorage: CredentialSlice = {
          expiredDate: addMinutes(new Date(), 10).getTime(),
          accessToken: newAccessToken,
        };
        sessionStorage.setItem(lineAccessTokenKey, JSON.stringify(tokenStorage));
        dispatch(CredentialActions.setCredential(tokenStorage));
      }
    }
  }, [expiredDate, accessToken, liffContext.liff, dispatch]);
  return accessToken;
};

export default useAccessToken;
