import React from 'react';
import { ValidationResultObject, ValidatorName } from 'generated';
import classNames from 'classnames';
import formatFullThaiDate from 'common/utility/formatFullThaiDate';

interface Props {
  error?: ValidationResultObject;
}

const ValidationError: React.FC<Props> = (props) => {
  const divClasses = classNames('p-2 mb-2', 'border rounded', 'bg-red-50 border-red-700');
  const renderAdditionalData = () => {
    if (props.error?.additionalData) {
      switch (props.error?.validatorName) {
        case ValidatorName.AddLeaveOverlapValidator: {
          const overlappedDates = props.error.additionalData as Array<string>;
          return overlappedDates.map((data) => (
            <p className="pl-2" key={data}>
              - {formatFullThaiDate(data)}
            </p>
          ));
        }
      }
    }
  };
  if (props.error) {
    return (
      <div className={divClasses}>
        <p className="font-semibold text-red-900 text-center border-b border-red-200 mb-2">
          ไม่สามารถทำรายการต่อได้
        </p>
        <p className="font-semibold text-red-900">เนื่องจาก " {props.error.message} "</p>
        {renderAdditionalData()}
      </div>
    );
  }
  return <div />;
};

export default ValidationError;
