/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    active: boolean;
}

export function CompanyDtoFromJSON(json: any): CompanyDto {
    return CompanyDtoFromJSONTyped(json, false);
}

export function CompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'active': json['active'],
    };
}

export function CompanyDtoToJSON(value?: CompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'active': value.active,
    };
}


