import { useMutation, UseMutationResult } from 'react-query';
import { AddLeaveRequest, Configuration, LeaveControllerApi, LeaveRecordDto } from 'generated';
import useAccessToken from 'common/liff/useAccessToken';

const useAddLeaveMutation = (): UseMutationResult<LeaveRecordDto, Response, AddLeaveRequest> => {
  const accessToken = useAccessToken();
  const api = new LeaveControllerApi(
    new Configuration({
      basePath: '',
      accessToken: accessToken,
    })
  );
  return useMutation((request) => api.addLeave(request));
};

export default useAddLeaveMutation;
