import React from 'react';
import Card from 'common/components/card/Card';
import { BeatLoader } from 'react-spinners';

const LoadingCard: React.FC = () => {
  return (
    <Card className="text-center">
      <BeatLoader color="#06B6D4" />
    </Card>
  );
};

export default LoadingCard;
