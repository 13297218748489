import ReactDOM from 'react-dom';
import React from 'react';
import 'styles/global.scss';
import Leave from 'pages/leave/Leave';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LiffProvider } from 'common/liff/Liff';
import { liffId } from 'common/Constant';
import { Provider } from 'react-redux';
import { leaveStore } from 'pages/leave/store/LeaveStore';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LiffProvider liffId={liffId}>
        <Provider store={leaveStore}>
          <Leave />
        </Provider>
      </LiffProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
