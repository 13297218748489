import Button from '../../../../common/components/button/Button';
import React from 'react';
import { FormikProps, withFormik } from 'formik';
import './LeaveStartForm.scss';
import classNames from 'classnames';
import CssTransition from '../../../../common/components/css-transition/CssTransition';
import { LeaveType } from 'generated';
import mapLeaveType from 'common/mapper/mapLeaveType';
import tomorrow from 'common/utility/tomorrow';
import { addDays, format, isBefore } from 'date-fns';
import { LeaveValues } from 'pages/leave/pages/leave-start/LeaveValues';
import formatLocalDate from 'common/utility/formatLocalDate';

const InnerLeaveStartForm: React.FC<FormikProps<LeaveValues>> = (props) => {
  const handleLeaveTypeChange = (leaveType: LeaveType) => () => {
    let minDate = new Date();
    if (leaveType === LeaveType.Business) {
      minDate = tomorrow();
    }
    props.setFieldValue('fromDate', format(minDate, 'yyyy-MM-dd'));
    props.setFieldValue('toDate', format(minDate, 'yyyy-MM-dd'));
    props.setFieldValue('leaveType', leaveType);
  };
  const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setFieldValue('fromDate', event.target.value);
    if (isBefore(new Date(props.values.toDate), new Date(event.target.value))) {
      props.setFieldValue('toDate', event.target.value);
    }
  };

  const sickClassNames = classNames('mr-2 w-1/3 sm:w-2/5 border border-red-700', {
    'text-red-700': props.values.leaveType !== LeaveType.Sick,
    'bg-red-700 text-white': props.values.leaveType === LeaveType.Sick,
  });
  const businessClassNames = classNames('w-1/3 sm:w-2/5 border border-cyan-700', {
    'text-cyan-700': props.values.leaveType !== LeaveType.Business,
    'bg-cyan-700 text-white': props.values.leaveType === LeaveType.Business,
  });

  return (
    <div className="leave-start-form">
      <div className="mb-3 flex justify-center">
        <Button className={sickClassNames} onClick={handleLeaveTypeChange(LeaveType.Sick)}>
          {mapLeaveType(LeaveType.Sick)}
        </Button>
        <Button className={businessClassNames} onClick={handleLeaveTypeChange(LeaveType.Business)}>
          {mapLeaveType(LeaveType.Business)}
        </Button>
      </div>
      <CssTransition in={props.values.leaveType === LeaveType.Business} timeout={200} classNames="leave-business">
        {(ref) => (
          <div ref={ref} className="leave-business mb-3">
            <div className="mb-3">
              <label className="block font-semibold">วันเริ่ม</label>
              <input
                name="fromDate"
                className="border p-2 rounded-xl appearance-none w-2/3 sm:w-1/2"
                type="date"
                min={format(tomorrow(), 'yyyy-MM-dd')}
                value={props.values.fromDate}
                onChange={handleFromDateChange}
              />
            </div>
            <div>
              <label className="block font-semibold">วันสิ้นสุด</label>
              <input
                name="toDate"
                className="border p-2 rounded-xl appearance-none w-2/3 sm:w-1/2"
                type="date"
                min={formatLocalDate(props.values.fromDate)}
                max={formatLocalDate(addDays(new Date(props.values.fromDate), 7))}
                value={props.values.toDate}
                onChange={props.handleChange}
              />
            </div>
          </div>
        )}
      </CssTransition>
      <div className="text-right">
        <Button className="bg-green-400 text-white px-5" disabled={!props.values.leaveType} onClick={props.submitForm}>
          ต่อไป
        </Button>
      </div>
    </div>
  );
};

interface Props {
  type?: LeaveType;
  fromDate: string;
  toDate: string;
  onSubmit: (values: LeaveValues) => void;
}

const LeaveStartForm = withFormik<Props, LeaveValues>({
  mapPropsToValues: (props): LeaveValues => ({
    leaveType: props.type,
    fromDate: props.fromDate,
    toDate: props.toDate,
  }),
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values);
  },
})(InnerLeaveStartForm);

export default LeaveStartForm;
