import React from 'react';
import { HashRouter } from 'react-router-dom';
import LeaveRoute from 'pages/leave/LeaveRoute';

const Leave: React.FC = () => {
  return (
    <div className="Page">
      <HashRouter>
        <LeaveRoute />
      </HashRouter>
    </div>
  );
};

export default Leave;
