/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddLeave,
    AddLeaveFromJSON,
    AddLeaveToJSON,
    GenericResult,
    GenericResultFromJSON,
    GenericResultToJSON,
    LeaveRecordDto,
    LeaveRecordDtoFromJSON,
    LeaveRecordDtoToJSON,
    ValidationResultObject,
    ValidationResultObjectFromJSON,
    ValidationResultObjectToJSON,
} from '../models';

export interface AddLeaveRequest {
    addLeave: AddLeave;
}

export interface CancelLeaveRequest {
    id: number;
}

export interface ValidateLeaveRequest {
    addLeave: AddLeave;
}

/**
 * 
 */
export class LeaveControllerApi extends runtime.BaseAPI {

    /**
     */
    async addLeaveRaw(requestParameters: AddLeaveRequest): Promise<runtime.ApiResponse<LeaveRecordDto>> {
        if (requestParameters.addLeave === null || requestParameters.addLeave === undefined) {
            throw new runtime.RequiredError('addLeave','Required parameter requestParameters.addLeave was null or undefined when calling addLeave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/leave`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddLeaveToJSON(requestParameters.addLeave),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaveRecordDtoFromJSON(jsonValue));
    }

    /**
     */
    async addLeave(requestParameters: AddLeaveRequest): Promise<LeaveRecordDto> {
        const response = await this.addLeaveRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async cancelLeaveRaw(requestParameters: CancelLeaveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelLeave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/leave/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cancelLeave(requestParameters: CancelLeaveRequest): Promise<void> {
        await this.cancelLeaveRaw(requestParameters);
    }

    /**
     */
    async validateLeaveRaw(requestParameters: ValidateLeaveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.addLeave === null || requestParameters.addLeave === undefined) {
            throw new runtime.RequiredError('addLeave','Required parameter requestParameters.addLeave was null or undefined when calling validateLeave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/leave/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddLeaveToJSON(requestParameters.addLeave),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async validateLeave(requestParameters: ValidateLeaveRequest): Promise<void> {
        await this.validateLeaveRaw(requestParameters);
    }

}
