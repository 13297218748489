/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GenericResult,
    GenericResultFromJSON,
    GenericResultToJSON,
    LeaveSummary,
    LeaveSummaryFromJSON,
    LeaveSummaryToJSON,
    MonthYear,
    MonthYearFromJSON,
    MonthYearToJSON,
    ValidationResultObject,
    ValidationResultObjectFromJSON,
    ValidationResultObjectToJSON,
} from '../models';

export interface SummaryByMonthYearRequest {
    monthYear: MonthYear;
}

/**
 * 
 */
export class LeaveSummaryControllerApi extends runtime.BaseAPI {

    /**
     */
    async summaryByMonthYearRaw(requestParameters: SummaryByMonthYearRequest): Promise<runtime.ApiResponse<Array<LeaveSummary>>> {
        if (requestParameters.monthYear === null || requestParameters.monthYear === undefined) {
            throw new runtime.RequiredError('monthYear','Required parameter requestParameters.monthYear was null or undefined when calling summaryByMonthYear.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/leave-summary/month-year`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonthYearToJSON(requestParameters.monthYear),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LeaveSummaryFromJSON));
    }

    /**
     */
    async summaryByMonthYear(requestParameters: SummaryByMonthYearRequest): Promise<Array<LeaveSummary>> {
        const response = await this.summaryByMonthYearRaw(requestParameters);
        return await response.value();
    }

}
