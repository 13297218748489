/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmployeeDto
 */
export interface EmployeeDto {
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    name: string;
    /**
     * 
     * @type {CompanyDto}
     * @memberof EmployeeDto
     */
    company: CompanyDto;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    role: EmployeeDtoRoleEnum;
}

/**
* @export
* @enum {string}
*/
export enum EmployeeDtoRoleEnum {
    User = 'ROLE_USER',
    Admin = 'ROLE_ADMIN'
}

export function EmployeeDtoFromJSON(json: any): EmployeeDto {
    return EmployeeDtoFromJSONTyped(json, false);
}

export function EmployeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'company': CompanyDtoFromJSON(json['company']),
        'active': json['active'],
        'role': json['role'],
    };
}

export function EmployeeDtoToJSON(value?: EmployeeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'company': CompanyDtoToJSON(value.company),
        'active': value.active,
        'role': value.role,
    };
}


