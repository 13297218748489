/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonthYear
 */
export interface MonthYear {
    /**
     * 
     * @type {number}
     * @memberof MonthYear
     */
    month: number;
    /**
     * 
     * @type {number}
     * @memberof MonthYear
     */
    year: number;
}

export function MonthYearFromJSON(json: any): MonthYear {
    return MonthYearFromJSONTyped(json, false);
}

export function MonthYearFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthYear {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': json['month'],
        'year': json['year'],
    };
}

export function MonthYearToJSON(value?: MonthYear | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'year': value.year,
    };
}


