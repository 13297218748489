import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeaveType } from 'generated';

export interface LeaveSlice {
  id?: number;
  type?: LeaveType;
  fromDate?: number;
  toDate?: number;
}

const initialState: LeaveSlice = {};

const leaveSlice = createSlice({
  name: 'leave',
  initialState,
  reducers: {
    setLeave: (state, action: PayloadAction<LeaveSlice>) => {
      const { id, type, fromDate, toDate } = action.payload;
      state.id = id;
      state.type = type;
      state.fromDate = fromDate;
      state.toDate = toDate;
    },
  },
});

export const { reducer, actions } = leaveSlice;
