import { useMutation, UseMutationResult } from 'react-query';
import { AddLeave, Configuration, LeaveControllerApi } from 'generated';
import useAccessToken from 'common/liff/useAccessToken';

const useValidateLeaveMutation = (): UseMutationResult<void, Response, AddLeave> => {
  const accessToken = useAccessToken();
  const api = new LeaveControllerApi(
    new Configuration({
      basePath: '',
      accessToken: accessToken,
    })
  );
  return useMutation((addLeave) => api.validateLeave({ addLeave }));
};

export default useValidateLeaveMutation;
