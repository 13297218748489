/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeaveRecordDto,
    LeaveRecordDtoFromJSON,
    LeaveRecordDtoFromJSONTyped,
    LeaveRecordDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaveDayDto
 */
export interface LeaveDayDto {
    /**
     * 
     * @type {string}
     * @memberof LeaveDayDto
     */
    date: string;
    /**
     * 
     * @type {Array<LeaveRecordDto>}
     * @memberof LeaveDayDto
     */
    leaves: Array<LeaveRecordDto>;
}

export function LeaveDayDtoFromJSON(json: any): LeaveDayDto {
    return LeaveDayDtoFromJSONTyped(json, false);
}

export function LeaveDayDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveDayDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'leaves': ((json['leaves'] as Array<any>).map(LeaveRecordDtoFromJSON)),
    };
}

export function LeaveDayDtoToJSON(value?: LeaveDayDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'leaves': ((value.leaves as Array<any>).map(LeaveRecordDtoToJSON)),
    };
}


