/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeaveType,
    LeaveTypeFromJSON,
    LeaveTypeFromJSONTyped,
    LeaveTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddLeave
 */
export interface AddLeave {
    /**
     * 
     * @type {LeaveType}
     * @memberof AddLeave
     */
    type: LeaveType;
    /**
     * 
     * @type {Date}
     * @memberof AddLeave
     */
    fromDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof AddLeave
     */
    toDate: Date;
}

export function AddLeaveFromJSON(json: any): AddLeave {
    return AddLeaveFromJSONTyped(json, false);
}

export function AddLeaveFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddLeave {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': LeaveTypeFromJSON(json['type']),
        'fromDate': (new Date(json['fromDate'])),
        'toDate': (new Date(json['toDate'])),
    };
}

export function AddLeaveToJSON(value?: AddLeave | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': LeaveTypeToJSON(value.type),
        'fromDate': (value.fromDate.toISOString().substr(0,10)),
        'toDate': (value.toDate.toISOString().substr(0,10)),
    };
}


