import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import { BeatLoader } from 'react-spinners';

interface Props {
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  isLoading?: boolean;
  type?: ButtonHTMLAttributes<any>['type'];
}

const Button: React.FC<Props> = (props) => {
  const className = classNames(
    'py-2 px-2 h-10 font-semibold rounded-lg shadow-md focus:outline-none disabled:bg-gray-300 text-center',
    props.className
  );
  return (
    <button type={props.type} disabled={props.disabled} onClick={props.onClick} className={className}>
      {!props.isLoading ? props.children : <BeatLoader color="white" size={10} margin={1} />}
    </button>
  );
};

export default Button;
