/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActivateEmployee,
    ActivateEmployeeFromJSON,
    ActivateEmployeeToJSON,
    AddRegisterCode,
    AddRegisterCodeFromJSON,
    AddRegisterCodeToJSON,
    EmployeeDto,
    EmployeeDtoFromJSON,
    EmployeeDtoToJSON,
    EmployeeRegister,
    EmployeeRegisterFromJSON,
    EmployeeRegisterToJSON,
    GenericResult,
    GenericResultFromJSON,
    GenericResultToJSON,
    RegisterCodeDto,
    RegisterCodeDtoFromJSON,
    RegisterCodeDtoToJSON,
    ValidationResultObject,
    ValidationResultObjectFromJSON,
    ValidationResultObjectToJSON,
} from '../models';

export interface ActivateEmployeeRequest {
    activateEmployee: ActivateEmployee;
}

export interface AddRegisterCodeRequest {
    addRegisterCode: AddRegisterCode;
}

export interface DeleteRegisterCodeRequest {
    code: string;
}

export interface RegisterRequest {
    employeeRegister: EmployeeRegister;
}

/**
 * 
 */
export class EmployeeControllerApi extends runtime.BaseAPI {

    /**
     */
    async activateEmployeeRaw(requestParameters: ActivateEmployeeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.activateEmployee === null || requestParameters.activateEmployee === undefined) {
            throw new runtime.RequiredError('activateEmployee','Required parameter requestParameters.activateEmployee was null or undefined when calling activateEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/employee/activate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivateEmployeeToJSON(requestParameters.activateEmployee),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activateEmployee(requestParameters: ActivateEmployeeRequest): Promise<void> {
        await this.activateEmployeeRaw(requestParameters);
    }

    /**
     */
    async addRegisterCodeRaw(requestParameters: AddRegisterCodeRequest): Promise<runtime.ApiResponse<RegisterCodeDto>> {
        if (requestParameters.addRegisterCode === null || requestParameters.addRegisterCode === undefined) {
            throw new runtime.RequiredError('addRegisterCode','Required parameter requestParameters.addRegisterCode was null or undefined when calling addRegisterCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/employee/code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddRegisterCodeToJSON(requestParameters.addRegisterCode),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterCodeDtoFromJSON(jsonValue));
    }

    /**
     */
    async addRegisterCode(requestParameters: AddRegisterCodeRequest): Promise<RegisterCodeDto> {
        const response = await this.addRegisterCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteRegisterCodeRaw(requestParameters: DeleteRegisterCodeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling deleteRegisterCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/employee/code/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteRegisterCode(requestParameters: DeleteRegisterCodeRequest): Promise<void> {
        await this.deleteRegisterCodeRaw(requestParameters);
    }

    /**
     */
    async getSelfRaw(): Promise<runtime.ApiResponse<EmployeeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/employee/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSelf(): Promise<EmployeeDto> {
        const response = await this.getSelfRaw();
        return await response.value();
    }

    /**
     */
    async listAllEmployeesRaw(): Promise<runtime.ApiResponse<Array<EmployeeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/employee/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmployeeDtoFromJSON));
    }

    /**
     */
    async listAllEmployees(): Promise<Array<EmployeeDto>> {
        const response = await this.listAllEmployeesRaw();
        return await response.value();
    }

    /**
     */
    async listRegisterCodesRaw(): Promise<runtime.ApiResponse<Array<RegisterCodeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/employee/code`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegisterCodeDtoFromJSON));
    }

    /**
     */
    async listRegisterCodes(): Promise<Array<RegisterCodeDto>> {
        const response = await this.listRegisterCodesRaw();
        return await response.value();
    }

    /**
     */
    async registerRaw(requestParameters: RegisterRequest): Promise<runtime.ApiResponse<EmployeeDto>> {
        if (requestParameters.employeeRegister === null || requestParameters.employeeRegister === undefined) {
            throw new runtime.RequiredError('employeeRegister','Required parameter requestParameters.employeeRegister was null or undefined when calling register.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/employee/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeeRegisterToJSON(requestParameters.employeeRegister),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeDtoFromJSON(jsonValue));
    }

    /**
     */
    async register(requestParameters: RegisterRequest): Promise<EmployeeDto> {
        const response = await this.registerRaw(requestParameters);
        return await response.value();
    }

}
