import React from 'react';
import Button from 'common/components/button/Button';
import classNames from 'classnames';

export interface CardData {
  title: string;
  value?: string;
}

interface Props {
  data: CardData[];
  leaveText?: string;
  calendarText?: string;
}

const CardSuccess: React.FC<Props> = (props) => {
  const bgCardClasses = classNames(
    'absolute',
    'inset-0',
    'bg-gradient-to-r from-blue-300 to-cyan-300',
    'transform -skew-y-5 -rotate-6',
    'sm:skew-y-4 sm:-rotate-3',
    'rounded-2xl'
  );
  const renderTableCards = () => {
    return props.data.map((card, index) => {
      const tableClasses = classNames('table-fixed w-full text-center', {
        'mb-2': index < props.data.length - 1,
      });
      return (
        <table key={card.title} className={tableClasses}>
          <tbody>
            <tr>
              <td className="w-1/2 p-2 border-r border-blue-300 font-semibold">{card.title}</td>
              <td className="w-1/2 p-2">{card.value}</td>
            </tr>
          </tbody>
        </table>
      );
    });
  };
  return (
    <div className="py-5 px-3">
      <div className="relative">
        <div className={bgCardClasses} />
        <div className="shadow border-2 border-b-0 border-cyan-600 rounded-t-xl p-2 sm:p-3 relative bg-white z-10">
          {renderTableCards()}
        </div>
        <div className="relative rounded-b-xl p-2 sm:p-3 bg-gradient-to-b from-cyan-600 bg-cyan-800 z-10">
          <table className="table-fixed w-full text-center">
            <tbody>
              <tr>
                <td>
                  <a href="/leave">
                    <Button className="w-3/4 p-2 bg-green-500 text-white">
                      {props.leaveText || 'เริ่มลา'}
                    </Button>
                  </a>
                </td>
                <td>
                  <a href="/calendar">
                    <Button className="w-3/4 p-2 bg-cyan-500 text-white">
                      {props.calendarText || 'ปฏิทิน'}
                    </Button>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CardSuccess;
