import { LeaveType } from 'generated';

const mapLeaveType = (type: LeaveType) => {
  switch (type) {
    case LeaveType.Sick:
      return 'ลาป่วย';
    case LeaveType.Business:
      return 'ลากิจ';
  }
};

export default mapLeaveType;
