import React, { useEffect, useState } from 'react';
import { LiffModel } from 'common/liff/LiffModel';
import LoadingCard from 'common/components/card/loading-card/LoadingCard';

interface LiffProviderProps {
  liffId: string;
}

interface LiffContext {
  liff?: Partial<LiffModel>;
}

const MOCK_LIFF: Partial<LiffModel> = {
  getAccessToken: () => 'mock-access-token',
};

const createLiffContext = () => {
  const context = React.createContext<LiffContext>({});

  const Provider: React.FC<LiffProviderProps> = (props) => {
    const liff: LiffModel = (window as any).liff;
    const [liffObject, setLiffObject] = useState<Partial<LiffModel>>();
    useEffect(() => {
      if (process.env.NODE_ENV === 'development') {
        setLiffObject(MOCK_LIFF);
        return;
      }
      if (liff) {
        liff.init({ liffId: props.liffId }).then(() => {
          if (liff.isInClient()) {
            setLiffObject(liff);
          } else {
            if (!liff.isLoggedIn()) {
              liff.login();
            } else {
              setLiffObject(liff);
            }
          }
        });
      }
    }, [liff, props.liffId]);
    const contextValue: LiffContext = {
      liff: liffObject,
    };
    const children = liffObject ? (
      props.children
    ) : (
      <div className="Page">
        <LoadingCard />
      </div>
    );
    return <context.Provider value={contextValue}>{children}</context.Provider>;
  };

  return {
    LiffConsumer: context.Consumer,
    LiffProvider: Provider,
    useLiffContext: () => React.useContext(context),
  };
};

export const { LiffProvider, useLiffContext } = createLiffContext();
