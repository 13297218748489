import React from 'react';
import CardSuccess from 'common/components/card-success/CardSuccess';
import { useSelector } from 'react-redux';
import { RootLeaveState } from 'pages/leave/store/LeaveStore';
import { LeaveSlice } from 'pages/leave/store/LeaveSlice';
import { Redirect } from 'react-router-dom';
import useEmployeeSelf from 'common/query/useEmployeeSelf';
import mapLeaveType from 'common/mapper/mapLeaveType';
import { format } from 'date-fns';
import th from 'date-fns/locale/th';

const LeaveSuccess: React.FC = () => {
  const employeeSelf = useEmployeeSelf();
  const leaveSlice = useSelector<RootLeaveState, LeaveSlice>((state) => state.leave);
  const { id, type, fromDate, toDate } = leaveSlice;
  if (!(id && type && fromDate && toDate)) {
    return <Redirect to="/?type=sick-leave" />;
  }
  return (
    <CardSuccess
      data={[
        { title: 'รหัสลา', value: String(id) },
        { title: 'ชื่อ', value: employeeSelf.data?.name },
        { title: 'แบบลา', value: mapLeaveType(type) },
        { title: 'วันเริ่ม', value: format(fromDate, 'dd MMM yyyy', { locale: th }) },
        { title: 'วันสิ้นสุด', value: format(toDate, 'dd MMM yyyy', { locale: th }) },
      ]}
      leaveText="ลาเพิ่ม"
    />
  );
};

export default LeaveSuccess;
