import { format } from 'date-fns';
import th from 'date-fns/locale/th';

export default function formatFullThaiDate(date?: number | Date | string) {
  if (!date) {
    return '';
  }
  let dateToFormat = date;
  if (typeof dateToFormat === 'string') {
    dateToFormat = new Date(dateToFormat);
  }
  return format(dateToFormat, 'วันiiiiที่ d MMMM yyyy', { locale: th });
}
