import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}
const Card: React.FC<Props> = (props) => {
  const classes = classNames(
    'p-4 w-full max-w-xl m-auto bg-white rounded-2xl shadow-md overflow-hidden',
    props.className
  );
  return <div className={classes}>{props.children}</div>;
};

export default Card;
