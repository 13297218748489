import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LeaveConfirm from './pages/leave-confirm/LeaveConfirm';
import Card from '../../common/components/card/Card';
import LeaveSuccess from './pages/leave-success/LeaveSuccess';
import CardHeader from 'common/components/card/CardHeader';
import LeaveStart from 'pages/leave/pages/leave-start/LeaveStart';
import useEmployeeSelf from 'common/query/useEmployeeSelf';
import { EmployeeDtoRoleEnum } from 'generated';
import LoadingCard from 'common/components/card/loading-card/LoadingCard';
import is4xxError from 'common/utility/is4xxError';

const LEAVE_PATH_TITLES = [
  { path: '/start', title: 'แจ้งลา' },
  { path: '/confirm', title: 'ยืนยันการลา' },
  { path: '/success', title: 'สำเร็จ' },
];

const LeaveRoute: React.FC = () => {
  const employeeSelf = useEmployeeSelf();
  if (employeeSelf.isLoading) {
    return <LoadingCard />;
  }
  if (employeeSelf.isError) {
    if (is4xxError(employeeSelf.error)) {
      window.location.assign('/register');
    } else {
      window.location.assign('/error');
    }
    return <div />;
  }
  if (employeeSelf.isSuccess) {
    if (employeeSelf.data && employeeSelf.data.role !== EmployeeDtoRoleEnum.User) {
      window.location.assign('/unauthorized');
      return <div />;
    }
  }
  return (
    <Card>
      <CardHeader name={employeeSelf?.data?.name} paths={LEAVE_PATH_TITLES} />
      <Switch>
        <Route exact path="/start">
          <LeaveStart />
        </Route>
        <Route exact path="/confirm">
          <LeaveConfirm />
        </Route>
        <Route exact path="/success">
          <LeaveSuccess />
        </Route>
        <Redirect to="/start" />
      </Switch>
    </Card>
  );
};

export default LeaveRoute;
