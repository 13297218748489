import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CredentialSlice {
  accessToken?: string;
  expiredDate?: number;
}

const initialState: CredentialSlice = {};

const credentialSlice = createSlice({
  name: 'credential',
  initialState,
  reducers: {
    setCredential: (state, action: PayloadAction<CredentialSlice>) => {
      state.accessToken = action.payload.accessToken;
      state.expiredDate = action.payload.expiredDate;
    },
    removeCredential: (state) => {
      state.accessToken = undefined;
      state.expiredDate = undefined;
    },
  },
});

export const { reducer, actions } = credentialSlice;
