import React, { useEffect, useState } from 'react';
import Button from '../../../../common/components/button/Button';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootLeaveState } from 'pages/leave/store/LeaveStore';
import { actions as LeaveActions, LeaveSlice } from 'pages/leave/store/LeaveSlice';
import mapLeaveType from 'common/mapper/mapLeaveType';
import classNames from 'classnames';
import { LeaveType, ValidationResultObject } from 'generated';
import { differenceInDays } from 'date-fns';
import useAddLeaveMutation from 'pages/leave/hooks/useAddLeaveMutation';
import useValidateLeaveMutation from 'pages/leave/hooks/useValidateLeaveMutation';
import ValidationError from 'common/components/validation-error/ValidationError';
import isValidationResult from 'common/utility/isValidationResult';
import createGenericValidationResult from 'common/utility/createGenericValidationResult';
import formatFullThaiDate from 'common/utility/formatFullThaiDate';

const LeaveConfirm: React.FC = () => {
  const addLeaveMutation = useAddLeaveMutation();
  const validateLeaveMutation = useValidateLeaveMutation();
  const dispatch = useDispatch();
  const history = useHistory();
  const leaveSlice = useSelector<RootLeaveState, LeaveSlice>((state) => state.leave);
  const [canContinue, setCanContinue] = useState(false);
  const [validationResult, setValidationResult] = useState<ValidationResultObject>();

  useEffect(() => {
    if (!canContinue) {
      if (leaveSlice.type && leaveSlice.fromDate && leaveSlice.toDate) {
        validateLeaveMutation.mutate(
          {
            type: leaveSlice.type,
            fromDate: new Date(leaveSlice.fromDate),
            toDate: new Date(leaveSlice.toDate),
          },
          {
            onSuccess: () => {
              !canContinue && setCanContinue(true);
            },
            onError: async (response) => {
              canContinue && setCanContinue(false);
              const error = await response.json();
              if (isValidationResult(error)) {
                setValidationResult(error);
              } else {
                setValidationResult(createGenericValidationResult());
              }
            },
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canContinue, leaveSlice]);

  if (!leaveSlice.type || !leaveSlice.fromDate || !leaveSlice.toDate) {
    return <Redirect to="/start" />;
  }

  const handleConfirmSubmit = async () => {
    const response = await addLeaveMutation.mutateAsync({
      addLeave: {
        type: leaveSlice.type!!,
        fromDate: new Date(leaveSlice.fromDate!!),
        toDate: new Date(leaveSlice.toDate!!),
      },
    });
    dispatch(LeaveActions.setLeave({ ...leaveSlice, id: response.id }));
    history.push('/success');
  };

  const leaveClasses = classNames('w-full cursor-default text-white', {
    'bg-red-700': leaveSlice.type === LeaveType.Sick,
    'bg-cyan-700': leaveSlice.type === LeaveType.Business,
  });
  return (
    <>
      <div className="mb-3">
        <Button className={leaveClasses}>{mapLeaveType(leaveSlice.type)}</Button>
      </div>
      <div className="mb-3">
        <label className="block font-semibold">ระยะเวลา</label>
        <p className="p-2 w-full">{differenceInDays(leaveSlice.toDate, leaveSlice.fromDate) + 1} วัน</p>
      </div>
      <div className="mb-3">
        <label className="block font-semibold">วันเริ่ม</label>
        <p className="p-2 w-full">{formatFullThaiDate(leaveSlice.fromDate)}</p>
      </div>
      <div className="mb-3">
        <label className="block font-semibold">วันสิ้นสุด</label>
        <p className="p-2 w-full">{formatFullThaiDate(leaveSlice.toDate)}</p>
      </div>
      <ValidationError error={validationResult} />
      <div className="text-right">
        <Link to="/start">
          <Button className="mr-2 bg-yellow-400 text-white px-5">แก้ไข</Button>
        </Link>
        <Button
          isLoading={addLeaveMutation.isLoading}
          onClick={handleConfirmSubmit}
          className="bg-green-400 text-white px-5 disabled:bg-gray-300"
          disabled={!canContinue}
        >
          ยืนยัน
        </Button>
      </div>
    </>
  );
};

export default LeaveConfirm;
