import { configureStore } from '@reduxjs/toolkit';
import { reducer as CredentialReducer } from 'common/redux/credential/CredentialSlice';
import { reducer as LeaveReducer } from 'pages/leave/store/LeaveSlice';

export const leaveStore = configureStore({
  reducer: {
    leave: LeaveReducer,
    credential: CredentialReducer,
  },
});

export type RootLeaveState = ReturnType<typeof leaveStore.getState>;
