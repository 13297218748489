import React, { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

interface Props {
  in: boolean;
  timeout: number;
  classNames: string;
  children: (ref: React.MutableRefObject<any>) => ReactNode;
}

const CssTransition: React.FC<Props> = (props) => {
  const ref = useRef<any>(null);
  return (
    <CSSTransition
      in={props.in}
      timeout={props.timeout}
      classNames={props.classNames}
      nodeRef={ref}
      unmountOnExit
    >
      {props.children(ref)}
    </CSSTransition>
  );
};

export default CssTransition;
