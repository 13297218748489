/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LeaveType {
    Sick = 'SICK',
    Business = 'BUSINESS'
}

export function LeaveTypeFromJSON(json: any): LeaveType {
    return LeaveTypeFromJSONTyped(json, false);
}

export function LeaveTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveType {
    return json as LeaveType;
}

export function LeaveTypeToJSON(value?: LeaveType | null): any {
    return value as any;
}

